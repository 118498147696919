import { DateTime } from "luxon";
import {
  MetricsTimePeriod,
  MetricsTimeSeriesResponse,
  ProjectInfo,
} from "../types";
import { User } from "../firebase";
import { Config } from "./config";

type APIErrorResponse = {
  status: string;
  code: number;
  error: string;
};

export type ContentType = 1 | 2 | 3 | 4;
export const ContentTypeToString: string[] = [
  "any",
  "charismatic",
  "active",
  "species",
  "stream",
];

export type MediaType = 1 | 2;
export const MediaTypeToString: string[] = ["any", "image", "video"];

type LatestMediaRequest = {
  project_id: number;
  count?: number;
  content_type?: ContentType;
  media_type?: MediaType;
  camera_id?: number;
};

type MediaRequest = {
  project_id: number;
  start?: Date;
  stop?: Date;
  content_type?: ContentType;
  media_type?: MediaType;
  camera_id?: number;
};

type EventsRequest = {
  timestamp: Date;
  camera_id: number;
};

export type Media = {
  id: string;
  project_id: number;
  media_type: MediaType;
  content_type: ContentType;
  time: Date;
  url: string;
};

// we need to remove hte plus from the formatted date
Date.prototype.toJSON = function () {
  return DateTime.fromJSDate(this).toISO();
};
// 2022-10-27T10:10:57Z
// 2022-10-13T11:10:48Z
export type FrameEvents = {
  [key: number]: FrameEvent[];
};
export type FrameEvent = {
  id: number;
  taxon_id: number;
  x: number;
  y: number;
  x_length: number;
  y_length: number;
};

export const FetchLatestMedia = async (
  user: User,
  data: LatestMediaRequest,
): Promise<Media[]> => {
  const accessToken = await user.getIdToken();
  let response = await fetch(Config.APIEndpoint + "/project/media/latest", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    return Promise.reject(Error("Bad server response"));
  }
};

export const FetchMedia = async (
  user: User,
  data: MediaRequest,
): Promise<Media[]> => {
  const accessToken = await user.getIdToken();
  let response = await fetch(Config.APIEndpoint + "/project/media", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    return Promise.reject(Error("Bad server response"));
  }
};

export const FetchRecentCameraEvents = async (
  user: User,
  data: EventsRequest,
): Promise<FrameEvents> => {
  const accessToken = await user.getIdToken();
  let response = await fetch(Config.APIEndpoint + "/project/events", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    return Promise.reject(Error("Bad server response"));
  }
};

export const FetchProjectInfo = async (
  user: User,
  id: number,
): Promise<ProjectInfo> => {
  const accessToken = await user.getIdToken();
  let response = await fetch(Config.APIEndpoint + "/project/" + id, {
    method: "GET",
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    return Promise.reject(Error("Bad server response"));
  }
};

export const FetchAllProjectInfo = async (
  user: User,
): Promise<ProjectInfo[]> => {
  const accessToken = await user.getIdToken();
  let response = await fetch(Config.APIEndpoint + "/project", {
    method: "GET",
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    return Promise.reject(Error("Bad server response"));
  }
};

export const FetchCameraMetrics = async (
  user: User,
  cameraID: number,
  start: Date,
  stop: Date,
  period: MetricsTimePeriod,
): Promise<MetricsTimeSeriesResponse> => {
  const accessToken = await user.getIdToken();
  let response = await fetch(Config.APIEndpoint + "/project/metrics", {
    method: "POST",
    body: JSON.stringify({
      camera_id: cameraID,
      start: start,
      stop: stop,
      period: period,
    }),
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    // todo handle response correctly
    return Promise.reject(Error("Bad server response"));
  }
};

export const FetchLatestDeploymentMetrics = async (
  user: User,
  deploymentID: number,
  durationSeconds: number,
  period: MetricsTimePeriod,
): Promise<MetricsTimeSeriesResponse> => {
  const accessToken = await user.getIdToken();
  let response = await fetch(Config.APIEndpoint + "/project/metrics/latest", {
    method: "POST",
    body: JSON.stringify({
      deployment_id: deploymentID,
      duration: `${durationSeconds}s`,
      period: period,
    }),
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  if (response.status === 200) {
    return await response.json();
  } else {
    // todo handle response correctly
    return Promise.reject(Error("Bad server response"));
  }
};

export const FetchMediaMetrics = async (
  user: User,
  mediaId: number,
): Promise<MetricsTimeSeriesResponse> => {
  // Return fake data for now.
  return Promise.resolve({
    metrics: [
      {
        metric: "MeanCt",
        value_map: [
          {
            value_list: [7.9],
            taxon_id: 1,
          },
          {
            value_list: [2.1],
            taxon_id: 2,
          },
        ],
      },
      {
        metric: "MaxN",
        value_map: [
          {
            value_list: [32],
            taxon_id: 1,
          },
          {
            value_list: [4],
            taxon_id: 2,
          },
        ],
      },
    ],
    interval: [new Date("2024-02-01T11:43:21.00")],
  });
};
