import { DateTime } from "luxon";
import { MetricsTimeSeries, MetricsTimeSeriesResponse } from "../../types";

export enum MetricStatus {
  UpToDate = "UPTODATE",
  Outdated = "OUTDATED",
  Running = "RUNNING",
  Offline = "OFFLINE",
  New = "NEW",
}

export interface TaggedMetric<V> {
  // TODO: better name
  value: V;
  status: MetricStatus;
  latestTime: DateTime;
}

export const meanCountMetric = (
  metrics: MetricsTimeSeriesResponse,
): TaggedMetric<number> => {
  if (!metrics) return null;
  return {
    value: meanCount(metricsByType(metrics, "MeanCt")).toFixed(2),
    status: MetricStatus.UpToDate,
    latestTime: latestTime(metrics),
  };
};

export const maxCountMetric = (
  metrics: MetricsTimeSeriesResponse,
): TaggedMetric<number> => {
  if (!metrics) return null;
  return {
    value: maxCount(metricsByType(metrics, "MaxN")),
    status: MetricStatus.Outdated,
    latestTime: latestTime(metrics),
  };
};

export const speciesEvennessMetric = (
  metrics: MetricsTimeSeriesResponse,
): TaggedMetric<number> => {
  if (!metrics) return null;
  return {
    value: speciesEvenness(metricsByType(metrics, "MeanCt")).toFixed(2),
    status: MetricStatus.Running,
    latestTime: latestTime(metrics),
  };
};

export const speciesDiversityMetric = (
  metrics: MetricsTimeSeriesResponse,
): TaggedMetric<number> => {
  if (!metrics) return null;
  return {
    value: speciesDiversity(metricsByType(metrics, "MeanCt"), 2).toFixed(2),
    status: MetricStatus.Offline,
    latestTime: latestTime(metrics),
  };
};

export const speciesRichnessMetric = (
  metrics: MetricsTimeSeriesResponse,
): TaggedMetric<number> => {
  if (!metrics) return null;
  return {
    value: speciesRichness(metricsByType(metrics, "MeanCt")),
    status: MetricStatus.UpToDate,
    latestTime: latestTime(metrics),
  };
};

export const speciesIdentifiedMetric = (
  metrics: MetricsTimeSeriesResponse,
  taxonomy: Object<number, Taxon>,
): TaggedMetric<Array<string>> => {
  if (!metrics) return null;
  return {
    value: speciesIdentified(metricsByType(metrics, "MeanCt"), taxonomy),
    status: MetricStatus.UpToDate,
    latestTime: latestTime(metrics),
  };
};

// View into a specific moment of time within the metrics
interface MetricSample {
  metrics: MetricsTimeSeries;
  sampleIndex: number;
}

interface TaxonMetrics {
  metrics: MetricsTimeSeries;
  taxonIndex: number;
}

function metricsByType(
  metrics: MetricsTimeSeriesResponse,
  type: string,
): MetricsTimeSeries | null {
  for (let ts of metrics.metrics) {
    if (ts.metric == type) {
      return ts;
    }
  }
  return null;
}

function latestTime(metrics: MetricsTimeSeriesResponse): DateTime {
  return DateTime.fromJSDate(metrics.interval[metrics.interval.length - 1]);
}

function sampleCount(metrics: MetricsTimeSeries) {
  let result = 0;
  for (let vm of metrics.value_map) {
    result = Math.max(result, vm.value_list.length);
  }
  return result;
}

function taxonCount(metrics: MetricsTimeSeries) {
  return metrics.value_map.length;
}

function* sampleValues(sample: MetricSample) {
  const vm = sample.metrics.value_map;
  for (let i = 0; i < vm.length; i++) {
    yield vm[i].value_list[sample.sampleIndex] || 0;
  }
}

function* taxonValues(taxonMetrics: TaxonMetrics) {
  const vl = taxonMetrics.metrics.value_map[taxonMetrics.taxonIndex].value_list;
  for (let i = 0; i < sampleCount(taxonMetrics.metrics); i++) {
    yield vl[i] || 0;
  }
}

function* samples(metrics: MetricsTimeSeries) {
  for (let i = 0; i < sampleCount(metrics); i++) {
    yield {
      metrics: metrics,
      sampleIndex: i,
    };
  }
}

function* taxonMetrics(metrics: MetricsTimeSeries) {
  for (let i = 0; i < taxonCount(metrics); i++) {
    yield {
      metrics: metrics,
      taxonIndex: i,
    };
  }
}

function reduceSample(
  sample: MetricSample,
  initial: number,
  f: (acc: number, cur: number) => number,
): number {
  let result = initial;
  for (let val of sampleValues(sample)) {
    result = f(result, val);
  }
  return result;
}

function reduceTaxon(
  tm: TaxonMetrics,
  initial: number,
  f: (acc: number, cur: number) => number,
): number {
  let result = initial;
  for (let val of taxonValues(tm)) {
    result = f(result, val);
  }
  return result;
}

// - Reduction across all species
function reducePerSample(
  metrics: MetricsTimeSeries,
  initial: number,
  f: (acc: number, cur: number) => number,
): number {
  let result = initial;
  for (let sample of samples(metrics)) {
    // a sample is all values (for each taxon) at a point in time
    result = f(
      result,
      reduceSample(sample, 0, (acc, cur) => acc + cur),
    );
  }
  return result;
}

// - Reduction across individual species, then combine
function reducePerTaxon(
  metrics: MetricsTimeSeries,
  initial: number,
  f: (acc: number, cur: number) => number,
): number {
  let result = initial;
  for (let tm of taxonMetrics(metrics)) {
    // taxa are the per-taxon time series
    result = f(
      result,
      reduceTaxon(tm, 0, (acc, cur) => acc + cur),
    );
  }
  return result;
}

// Works for metrics being counts or means.
function meanCount(metrics: MetricsTimeSeries): number {
  // return sum / count
  return (
    reducePerSample(metrics, 0, (acc, cur) => acc + cur) /
    reducePerSample(metrics, 0, (acc, cur) => acc + 1)
  );
}

// Only works if metrics are counts, or maxs, not means.
function maxCount(metrics: MetricsTimeSeries): number {
  return reducePerSample(metrics, 0, Math.max);
}

// Works for metrics being counts or means.
function speciesEvenness(metrics: MetricsTimeSeries): number {
  const total = reducePerTaxon(metrics, 0, (acc, cur) => acc + cur);
  return -reducePerTaxon(metrics, 0, (acc, cur) => {
    return acc + (cur / total) * Math.log(cur / total);
  });
}

// Works for metrics being counts or means.
function speciesDiversity(metrics: MetricsTimeSeries, q: number): number {
  const total = reducePerTaxon(metrics, 0, (acc, cur) => acc + cur);
  return Math.pow(
    reducePerTaxon(metrics, 0, (acc, cur) => {
      return acc + Math.pow(cur / total, q);
    }),
    1 / (1 - q),
  );
}

function speciesRichness(metrics: MetricsTimeSeries): number {
  return reducePerTaxon(metrics, 0, (acc, cur) => acc + 1);
}

function speciesIdentified(
  metrics: MetricsTimeSeries,
  taxonomy: Object<number, Taxon>,
): Array<string> {
  const result = [];
  for (let vm of metrics.value_map) {
    if (taxonomy[vm.taxon_id] != null) {
      result.push(taxonomy[vm.taxon_id].common_name);
    }
  }
  return result;
}
